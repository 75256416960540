import React from "react"

import { Body, H1 } from "@src/components"
import Layout from "@src/components/layout"
import SEO from "@src/components/seo"

const NotFoundPage = () => (
  <Layout locale="nl">
    <SEO title="404: Not found" />
    <H1>404</H1>
    <Body>Deze pagina bestaat niet</Body>
  </Layout>
)

export default NotFoundPage
